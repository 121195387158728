import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Column = makeShortcode("Column");
const Box = makeShortcode("Box");
const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "ohenton-karihwatehkwen",
      "style": {
        "position": "relative"
      }
    }}>{`Ohen:ton Karihwatehkwen`}<a parentName="h1" {...{
        "href": "#ohenton-karihwatehkwen",
        "aria-label": "ohenton karihwatehkwen permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <h2 {...{
      "id": "the-words-that-come-before-all-else",
      "style": {
        "position": "relative"
      }
    }}>{`The Words That Come Before All Else`}<a parentName="h2" {...{
        "href": "#the-words-that-come-before-all-else",
        "aria-label": "the words that come before all else permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Today we have gathered and we see that the cycles of life continue. We have been given the duty to live in balance and harmony with each other and all living things. So now, we bring our minds together as one as we give greetings and thanks to each other as people. Now our minds are one.`}</p>
    <p>{`We are all thankful to our Mother, the Earth, for she gives us all that we need for life. She supports our feet as we walk about upon her. It gives us joy that she continues to care for us as she has from the beginning of time. To our mother, we send greetings and thanks. Now our minds are one.`}</p>
    <p>{`We give thanks to all the waters of the world for quenching our thirst and providing us with strength. Water is life. We know its power in many forms‐ waterfalls and rain, mists and streams, rivers and oceans. With one mind, we send greetings and thanks to the spirit of Water. Now our minds are one. `}</p>
    <p>{`We turn our minds to all the Fish life in the water. They were instructed to cleanse and purify the water. They also give themselves to us as food. We are grateful that we can still find pure water. So, we turn now to the Fish and send our greetings and thanks. Now our minds are one.`}</p>
    <p>{`Now we turn toward the vast fields of Plant life. As far as the eye can see, the Plants grow, working many wonders. They sustain many life forms. With our minds gathered together, we give thanks and look forward to seeing Plant life for many generations to come. Now our minds are one.`}</p>
    <p>{`With one mind, we turn to honor and thank all the Food Plants we harvest from the garden. Since the beginning of time, the grains, vegetables, beans and berries have helped the people survive. Many other living things draw strength from them too. We gather all the Plant Foods together as one and send them a greeting of thanks. Now our minds are one.`}</p>
    <p>{`Now we turn to all the Medicine herbs of the world. From the beginning they were instructed to take away sickness. They are always waiting and ready to heal us. We are happy there are still among us those special few who remember how to use these plants for healing. With one mind, we send greetings and thanks to the Medicines and to the keepers of the Medicines. Now our minds are one.`}</p>
    <p>{`We gather our minds together to send greetings and thanks to all the Animal life in the world. They have many things to teach us as people. We are honored by them when they give up their lives so we may use their bodies as food for our people. We see them near our homes and in the deep forests. We are glad they are still here and we hope that it will always be so. Now our minds are one.`}</p>
    <p>{`We now turn our thoughts to the Trees. The Earth has many families of Trees who have their own instructions and uses. Some provide us with shelter and shade, others with fruit, beauty and other useful things. Many people of the world use a Tree as a symbol of peace and strength. With one mind, we greet and thank the Tree life. Now our minds are one.`}</p>
    <p>{`We put our minds together as one and thank all the Birds who move and fly about over our heads. The Creator gave them beautiful songs. Each day they remind us to enjoy and appreciate life. The Eagle was chosen to be their leader. To all the Birds ‐ from the smallest to the largest ‐ we send our joyful greetings and thanks. Now our minds are one.`}</p>
    <p>{`We are all thankful to the powers we know as the Four Winds. We hear their voices in the moving air as they refresh us and purify the air we breathe. They help us to bring the change of seasons. From the four directions they come, bringing us messages and giving us strength. With one mind, we send our greetings and thanks to the Four Winds. Now our minds are one.`}</p>
    <p>{`Now we turn to the west where our grandfathers, the Thunder Beings, live. With lightning and thundering voices, they bring with them the water that renews life. We are thankful that they keep those evil things made by Okwiseres underground. We bring our minds together as one to send greetings and thanks to our Grandfathers, the Thunderers. Now our minds are one.`}</p>
    <p>{`We now send greetings and thanks to our eldest Brother, the Sun. Each day without fail he travels the sky from east to west, bringing the light of a new day. He is the source of all the fires of life. With one mind, we send greetings and thanks to our Brother, the Sun. Now our minds are one.`}</p>
    <p>{`We put our minds together to give thanks to our oldest Grandmother, the Moon, who lights the night‐time sky. She is the leader of woman all over the world, and she governs the movement of the ocean tides. By her changing face we measure time, and it is the Moon who watches over the arrival of children here on Earth. With one mind, we send greetings and thanks to our Grandmother, the Moon. Now our minds are one.`}</p>
    <p>{`We give thanks to the Stars who are spread across the sky like jewelry. We see them in the night, helping the Moon to light the darkness and bringing dew to the gardens and growing things. When we travel at night, they guide us home. With our minds gathered together as one, we send greetings and thanks to the Stars. Now our minds are one.`}</p>
    <p>{`We gather our minds to greet and thank the enlightened Teachers who have come to help throughout the ages. When we forget how to live in harmony, they remind us of the way we were instructed to live as people. With one mind, we send greetings and thanks to these caring teachers. Now our minds are one.`}</p>
    <p>{`Now we turn our thoughts to the creator, or Great Spirit, and send greetings and thanks for all the gifts of Creation. Everything we need to live a good life is here on this Mother Earth. For all the love that is still around us, we gather our minds together as one and send our choicest words of greetings and thanks to the Creator. Now our minds are one.`}</p>
    <p>{`We have now arrived at the place where we end our words. Of all the things we have named, it was not our intention to leave anything out. If something was forgotten, we leave it to each individual to send such greetings and thanks in their own way. Now our minds are one.`}</p>
    <h2 {...{
      "id": "teaching-thanksgiving",
      "style": {
        "position": "relative"
      }
    }}>{`Teaching Thanksgiving`}<a parentName="h2" {...{
        "href": "#teaching-thanksgiving",
        "aria-label": "teaching thanksgiving permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <Column sx={{
      gap: "10%"
    }} mdxType="Column">
      <Box mdxType="Box">
        <p><strong parentName="p">{`Thankgiving`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/Y36Nt3i3z0E" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Understanding`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/swJs2cGNwIU" mdxType="Video" />
      </Box>
    </Column>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      